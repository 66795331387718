var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('a-card',{staticClass:"card-signup header-solid h-full",attrs:{"bordered":false,"bodyStyle":{paddingTop: 0}}},[_c('p',{staticClass:"text-center my-25 font-semibold text-muted"},[_vm._v("Registro")]),_c('br'),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'name',
						{ rules: [{ required: _vm.isloginWithEmail, message: 'Campo requerido' }] } ]),expression:"[\n\t\t\t\t\t\t'name',\n\t\t\t\t\t\t{ rules: [{ required: isloginWithEmail, message: 'Campo requerido' }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Name"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'email',
						{ rules: [{ required: _vm.isloginWithEmail, message: 'Campo requerido' }] } ]),expression:"[\n\t\t\t\t\t\t'email',\n\t\t\t\t\t\t{ rules: [{ required: isloginWithEmail, message: 'Campo requerido' }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Email"}})],1),_c('a-form-item',{staticClass:"mb-5"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'password',
						{ rules: [{ required: _vm.isloginWithEmail, message: 'Campo requerido'  }] } ]),expression:"[\n\t\t\t\t\t\t'password',\n\t\t\t\t\t\t{ rules: [{ required: isloginWithEmail, message: 'Campo requerido'  }] },\n\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Password"}})],1),_c('a-form-item',{staticClass:"mb-5"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'phone',
						{ rules: [{ required: true, message: 'Campo requerido'  }] } ]),expression:"[\n\t\t\t\t\t\t'phone',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Campo requerido'  }] },\n\t\t\t\t\t\t]"}],attrs:{"type":"text","placeholder":"Número teléfonico"}})],1),_c('a-form-item',{staticClass:"mb-5"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'address',
						{ rules: [{ required: true, message: 'Campo requerido' }] } ]),expression:"[\n\t\t\t\t\t\t'address',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Campo requerido' }] },\n\t\t\t\t\t\t]"}],attrs:{"type":"text","placeholder":"Dirección"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" REGISTRAR ")])],1)],1),_c('p',{staticClass:"font-semibold text-muted text-center"},[_vm._v("Ya tienes una cuenta? "),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":"/sign-in"}},[_vm._v("Iniciar sesión")])],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-up-header",staticStyle:{"background-image":"url('https://media.istockphoto.com/id/1325705911/vector/futuristic-geometric-golden-yellow-gradation-background-diagonal-line-vector-illustration.jpg?s=612x612&w=0&k=20&c=UyAKU3yhHMhQiSYDvIp1xOEV715LOxHjlnIgTDrYLVw=')"}},[_c('div',{staticClass:"content"},[_c('h1',{staticClass:"mb-5"},[_vm._v("Holaa")]),_c('p',{staticClass:"text-lg"},[_vm._v("Crea tu cuenta y disfruta de lo que ofrecemos.")])])])}]

export { render, staticRenderFns }